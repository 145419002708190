@import '../functions';
$primary: #facf66;
@import '../variables';
@import '../mixins';

// HOME B2C

// Hero

.hero-home{
	background-image: url('../../img/background/bg-home-b2c.jpg');
	background-size: cover;
	background-position: center top;
	position: relative;

	&.univers-sport{
		background-image: url('../../img/background/bg-home-b2c-sport.jpg');
	}
}

// Home

.home{

	&.stories,
	&.newsletter,
	&.testimonials{
		.title{
			h2{
				color: $primary;
			}
		}
	}

	// Stories

	&.stories{
		.story-link.see_more{
			a{
				background-color: $primary;
				color: $white;
				&:hover{background-color: darken($primary, 10%);}
			}
		}
	}

	&.stories.univers-sport{
		.title h1{
			text-align: right;
		}
	}

	&.stories:not(.univers-sport){

		@include media-breakpoint-up(md) {
			background-image: url('../../img/background/bg-chemise.png');
			background-position: bottom 5% left;
			background-repeat: no-repeat;
		}

		.stories-wrapper {
			@include media-breakpoint-up(md) {
				background-image: url('../../img/background/bg-fer.png');
				background-position: bottom -20% left 10%;
				background-repeat: no-repeat;
			}
		}
	}

	// How to

	&.how_to{
		.how_to-overview{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-color: $primary;
		}
	}

	&.how_to:not(.univers-sport){
		background-image: url('../../img/background/bg-rouleau.png');
		background-position: bottom $spacer * 4 right;
		background-repeat: no-repeat;
	}

	// Newsletter

	&.newsletter{
		background-image: url('../../img/background/newsletter.jpg');
		background-position: center right;
		background-repeat: no-repeat;
		background-color: white;
	}

	// b2c blocks

	&.testimonials{
		padding: $spacer * 5 0;
		background-color: #f7f2ef;

		@include media-breakpoint-up(md) {
			background-image: url('../../img/background/bg-testimonials.jpg');
			background-size: cover;
			background-position: center right -470px;
		}

		@include media-breakpoint-up(lg) {
			background-position: center right -250px;
		}

		@include media-breakpoint-up(xl) {
			background-position: center right;
		}

		.title{
			h2{
				margin-top: 0;
			}
		}

		p.lead{
			font-weight: 700;
			color: $dark;
			margin-top: $spacer * 2;
		}

		p{
			color: $secondary;
		}

		.see_more{
			color: $salmon;
		}
	}

	// UNIVERS BLOCKS

	// Univers Sport

	&.background.univers-sport{
		background-color: $white;
		
		@include media-breakpoint-up(lg) {
			background-image: url('../../img/background/bg-running.jpg');
			background-repeat: no-repeat;
		}

		@include media-breakpoint-only(lg) {
			background-position: left 41% bottom 655px;
			background-size: 1840px;
		}

		@include media-breakpoint-up(xl) {
			background-position: left 39% bottom 414px;
			background-size: 2535px;
		}
	}
}